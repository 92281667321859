import { createRouter, createWebHistory } from "vue-router";
import emptyComp from '../components/emptyComp.vue';
import userView from '../components/userView.vue';
import err from '../components/ErrorLog.vue';

const routes = [{
    path: '/:id/Admin', 
    component: emptyComp
},{
    path: '/:id',
    component: userView
},{
    path: '/',
    component: err
}]
const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;