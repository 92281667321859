<template>
<div class="mainDiv">
    <!-- <font-awesome-icon class="fa-solid fa-user-secret" /> -->
    <link href='https://fonts.googleapis.com/css?family=Noto Sans' rel='stylesheet'>
    <meta 
     name='viewport' 
     content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' 
/>
    <!-- <i class="material-icons" style="font-size:30px">whatsapp</i> -->
    <router-view/>
</div>
</template>
<script>
console.log("Hello")
export default {
  
}
</script>
<style scoped>
.mainDiv{
    font-family: 'Noto Sans';
}
i{
    color: green;
}
</style>

