// A31ECCGH
// 931ECCGH
//arti.rakholiya@avinashi.com
// https://goo.gl/maps/8XgMYxomFhwNruBd7
//link, call, email, whatsapp, download, location, vcard, reviews, website
import { createApp } from 'vue';
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router';
import BootstrapVue3 from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import Vue3TouchEvents from "vue3-touch-events";

loadFonts()
const app = createApp(App);

app.use(vuetify).use(Vue3TouchEvents).use(BootstrapVue3).use(router).mount('#app')
