<template>
  <div>
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"
    />
    <div v-if="showpersonal">
      <div class="up">
        <img
          class="mainlogo"
          :src="require('@/assets/backarr.png')"
          alt="mylogo"
        />
        <h2 class="adminhead">Personal Page</h2>
      </div>
      <div v-if="arr1.length === 0">
        <noItem />
      </div>
      <div v-else v-for="(i, index) in arr1" :key="i.id" class="outer">
        <div
          class="main"
          v-if="arr1.length !== 0 && arr1[index][0] == 'Whatsapp'"
        >
          <!-- <v-list-item @click="openForm(index)" link :title="arr2[index]">
          <template v-slot:append>
            <v-badge
              color="error"
              :content="arr1[index].length"
              inline
            ></v-badge>
          </template>
        </v-list-item> -->
          <b-alert
            show
            :variant="alertCol"
            @click="openForm(index)"
            class="point"
          >
            <span class="names">
              <!-- <span v-if="index === 2">
                <img class="myicon" :src="iconArr[index][1]" alt="mylogo" />
                <img class="myicon1" :src="iconArr[index][0]" alt="mylogo" />
              </span>
              <img v-else class="myicon" :src="iconArr[index]" alt="mylogo" /> -->
              <table>
                <tr>
                  <th rowspan="2">
                    <img
                      class="myimg"
                      :src="require('@/assets/wa.png')"
                      alt="mylogo"
                    />
                  </th>
                  <td>
                    <span class="dis">{{ arr1[index][1] }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="dis fsm">{{ arr1[index][2] }}</span>
                  </td>
                </tr>
              </table>
              <!-- <b-badge class="badgepos" :variant="alertArr[index]">{{
                arr1[index].length
              }}</b-badge> -->
            </span>
          </b-alert>
        </div>
        <div
          class="main"
          v-else-if="arr1.length !== 0 && arr1[index][0] == 'Location'"
        >
          <b-alert
            show
            :variant="alertCol"
            @click="openForm(index)"
            class="point"
          >
            <span class="names">
              <table>
                <tr>
                  <th rowspan="2">
                    <img
                      class="myimg"
                      :src="require('@/assets/location.png')"
                      alt="mylogo"
                    />
                  </th>
                  <td>
                    <span class="dis">{{ arr1[index][1] }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="dis fsm">{{ arr1[index][2] }}</span>
                  </td>
                </tr>
              </table>
            </span>
          </b-alert>
        </div>
        <div
          class="main"
          v-else-if="arr1.length !== 0 && arr1[index][0] == 'Link'"
        >
          <b-alert
            show
            :variant="alertCol"
            @click="openForm(index)"
            class="point"
          >
            <span class="names">
              <table>
                <tr>
                  <th rowspan="2">
                    <img
                      class="myicon mylink"
                      :src="require('@/assets/link2.png')"
                      alt="mylogo"
                    />
                    <img
                      class="myicon1 mylink"
                      :src="require('@/assets/link1.png')"
                      alt="mylogo"
                    />
                  </th>
                  <span class="dis">{{ arr1[index][1] }}</span>
                </tr>
                <tr>
                  <span class="dis fsm">{{ arr1[index][2] }}</span>
                </tr>
              </table>
            </span>
          </b-alert>
        </div>
        <div
          class="main"
          v-else-if="arr1.length !== 0 && arr1[index][0] == 'Call'"
        >
          <b-alert
            show
            :variant="alertCol"
            @click="openForm(index)"
            class="point"
          >
            <span class="names">
              <table>
                <tr>
                  <th rowspan="2">
                    <img
                      class="myimg"
                      :src="require('@/assets/call1.png')"
                      alt="mylogo"
                    />
                  </th>
                  <td>
                    <span class="dis">{{ arr1[index][1] }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="dis fsm">{{ arr1[index][2] }}</span>
                  </td>
                </tr>
              </table>
            </span>
          </b-alert>
        </div>
        <div class="main" v-else-if="arr1.length !== 0">
          <b-alert
            show
            :variant="alertCol"
            @click="openForm(index)"
            class="point"
          >
            <span class="names">
              <table>
                <tr>
                  <td>
                    <span class="dis">{{ arr1[index][1] }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="dis fsm">{{ arr1[index][2] }}</span>
                  </td>
                </tr>
              </table>
            </span>
          </b-alert>
        </div>
        <!-- <div class="main" v-else-if="arr1.length !== 0">
          <b-alert
            show
            :variant="alertArr[index]"
            @click="openForm(index)"
            class="point"
          >
            <span class="names">
              {{ arr1[index][0] }}
            </span>
          </b-alert>
        </div> -->
      </div>
      <div>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent>
            <template v-slot:activator="{ props }">
              <div class="down">
                <v-btn class="addbtn" v-bind="props"> + </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Add-Record</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <div class="content cont">
                      <br />
                      <select v-model="selectedVal" class="selection" required>
                        <option value="" disabled selected>Select</option>
                        <option v-for="i in arr2" :key="i.id">{{ i }}</option>
                      </select>
                      <div></div>
                      <v-col cols="12">
                        <v-text-field
                          class="tfw"
                          label="Enter Label"
                          v-model="key"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          class="tfw"
                          label="Enter Value"
                          v-model="val"
                          required
                        ></v-text-field>
                      </v-col>
                    </div>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue-darken-1" text @click="add"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div v-if="formDialog">
        <addrec
          :email="email"
          :password="password"
          :num="myNum"
          :arr="arr1"
          @closeDialog="formDialog = false"
        />
      </div>
    </div>
    <div>
      <div class="d1" v-if="num1">
        <div class="headingdiv">
          <img
            :src="require('@/assets/NFCLogo.png')"
            alt="mylogo"
            class="companylogo"
          />
        </div>
        <v-container>
          <v-col cols="12">
            <div class="new-chat-window">
              <!-- <img
                class="fa fa-search loginlogo"
                :src="require('@/assets/mail.png')"
                alt="mail"
              /> -->
              <i class="bi bi-envelope loginlogo1"></i>
              <input
                type="text"
                class="new-chat-window-input"
                v-model="email"
                required
              />
            </div>
            <div class="new-chat-window">
              <!-- <img
                class="fa fa-search loginlogo"
                :src="require('@/assets/key.png')"
                alt="mail"
              /> -->
              <i class="bi bi-key loginlogo"></i>
              <input
                v-if="showPassword"
                type="text"
                class="new-chat-window-input pos"
                v-model="password"
                required
              />
              <input
                v-else
                type="password"
                class="new-chat-window-input pos"
                v-model="password"
                required
              />
              <span class="icon is-small is-right poseye" @click="toggleShow">
                <i
                  class="bi"
                  :class="{
                    'bi-eye-slash': showPassword,
                    'bi-eye': !showPassword,
                  }"
                ></i>
              </span>
            </div>

            <!-- <v-text-field
              type="password"
              label="Enter Password"
              v-model="password"
              required
            ></v-text-field> -->
          </v-col>
          <button @click="getData()" class="btn">Login</button>
        </v-container>
      </div>
    </div>
    <div v-if="num2">
      <div class="btnContainer">
      <button class="logoutbtn" @click="handleLogout">
        <i class="bi bi-box-arrow-right"></i>
      </button>
      </div>
      <mainPage />
    </div>
  </div>
</template>

<script>
import addrec from "./addRec.vue";
import noItem from "./noItems.vue";
import mainPage from "./mainPage.vue";
export default {
  name: "emptyComp",
  mounted() {
    this.getData1();
  },
  data() {
    return {
      showPassword: false,
      url: "",
      pass: null,
      arr1: [],
      arr2: ["Whatsapp", "Call", "Link", "Location"],
      keyArr: ["type", "label", "value"],
      dialog: false,
      type: "",
      key: "",
      val: "",
      tempArr: [],
      temp: [],
      selectedVal: "",
      myNum: 0,
      formDialog: false,
      num1: true,
      num2: false,
      email: "",
      password: "",
      // iconArr: [
      //   require("@/assets/wa.png"),
      //   require("@/assets/call.png"),
      //   [require("@/assets/link1.png"), require("@/assets/link2.png")],
      //   require("@/assets/location.png"),
      // ],
      alertCol: "light",
      showpersonal: false,
      byteCardUrl: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  components: {
    addrec,
    noItem,
    mainPage,
  },
  methods: {
    handleLogout() {
      localStorage.removeItem(this.$route.params.id);
      //this.$router.push("/" + this.$route.params.id);
      var myHeaders = new Headers();
      myHeaders.append("token", "4765be9f-a47a-46e8-9105-49bc5654cf53");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch("https://api.bytecard.in/api/nfc/logout", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      
      this.num2 = false;
      this.num1 = true;
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    add() {
      // this.selectedVal == "WhatsApp"
      //   ? this.arr1[0].push({ [this.key]: this.val })
      //   : this.selectedVal == "Call"
      //   ? this.arr1[1].push({ [this.key]: this.val })
      //   : this.selectedVal == "Link"
      //   ? this.arr1[2].push({ [this.key]: this.val })
      //   : this.arr1[3].push({ [this.key]: this.val });
      if (this.selectedVal === "" || this.key === "" || this.val === "") {
        alert("Any of the fields cannot be empty...");
        this.selectedVal = "";
        this.key = "";
        this.val = "";
      } else if (
        (this.selectedVal === "Whatsapp" || this.selectedVal === "Call") &&
        (this.val.length > 12 || this.val.length < 10)
      ) {
        alert("Invalid Number...");
      } else {
        this.dialog = false;
        this.temp = [this.selectedVal, this.key, this.val];
        this.arr1.push(this.temp);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("token", "935c37eb-8c56-4041-8d56-7bcbe896aa0c");

        var options = '{"items":[';
        for (var i = 0; i < this.arr1.length; i++) {
          options = options.concat('{"');
          for (var j = 0; j < 3; j++) {
            if (j === 2) {
              options = options.concat(
                this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
              );
            } else {
              options =
                options.concat(
                  this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
                ) + ',"';
            }
          }
          if (i !== this.arr1.length - 1) {
            options = options.concat("},");
          } else {
            options = options.concat("}]}");
          }
        }
        var myUrl = '"' + this.$route.params.id + '"';

        console.log("Options ->" + options);
        var raw =
          '{\r\n"hwId": ' +
          myUrl +
          ',\r\n"action":{ "sample": "Test123","longitude":21.44 },\r\n"options":' +
          options +
          "}";
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        fetch("https://api.bytecard.in/api/nfc/update", requestOptions)
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
        console.warn(raw);
        alert("Saved Successfully...");
        this.selectedVal = "";
        this.key = "";
        this.val = "";
      }
    },
    openForm(index) {
      this.formDialog = true;
      this.myNum = index;
    },
    getData1() {
      var formdata;
      var requestOptions;
      if (localStorage.getItem(this.$route.params.id)) {
        // alert(localStorage.getItem(this.$route.params.id));
        formdata = new FormData();
        formdata.append("hwId", this.$route.params.id);

        requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        fetch("https://api.bytecard.in/api/nfc/get", requestOptions)
          .then((response) => response.text())
          .then((result) => this.printData1(result))
          .catch((error) => console.log(error));
      }
    },
    getData() {
      var formdata;
      var requestOptions;
      formdata = new FormData();
      console.log(this.arr1);
      console.log(this.$route.params.id);
      formdata.append("email", this.email);
      formdata.append("password", this.password);
      formdata.append("productId", "a9ec6071-ab8c-479e-b3d0-ee0cbfc68ae8");
      formdata.append("hwId", this.$route.params.id);

      requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch("https://api.bytecard.in/api/nfc/login", requestOptions)
        .then((response) => response.text())
        .then((result) => this.printData(result))
        .catch((error) => alert(error));
    },
    printData(result) {
      var arr = JSON.parse(result);
      if (arr.status == false) {
        alert(arr.message);
        console.log(arr);
      } else {
        localStorage.setItem(this.$route.params.id, arr.data.token);
        console.log(arr.data.options);
        //this.arr1 = Object.entries(arr.data.options);
        if (arr.data.options == null) {
          console.log("hello");
          this.num1 = false;
          this.num2 = true;
          this.byteCardUrl = arr.data.profile.bytecard;
        } else {
          this.tempArr = Object.values(arr.data.options.items);
          for (var i = 0; i < this.tempArr.length; i++) {
            this.arr1.push(Object.values(this.tempArr[i]));
          }
          //this.arr1 = [["Link", "Google", "https://www.google.com"], ["Call", "Home", "9106884674"], ["Call", "Office", "9879364472"], ["Whatsapp", "Office", "9106884674"], ["Whatsapp", "Home", "7878962975"], ["Location", "Location", "https://maps.app.goo.gl/vKBKrocP7XaeJFH19"], ["Whatsapp", "Shop", "9106884674"]],
          console.log(this.arr1);
          this.num1 = false;
          this.num2 = true;
          this.byteCardUrl = arr.data.profile.bytecard;
        }
      }
    },
    printData1(result) {
      var arr = JSON.parse(result);
      if (arr.status == false) {
        alert(arr.message);
        console.log(arr);
      } else {
        // localStorage.setItem(this.$route.params.id, arr.data.token);
        console.log(arr.data.options);
        //this.arr1 = Object.entries(arr.data.options);
        if (arr.data.options == null) {
          console.log("hello");
          this.num1 = false;
          this.num2 = true;
          this.byteCardUrl = arr.data.profile.bytecard;
        } else {
          this.tempArr = Object.values(arr.data.options.items);
          for (var i = 0; i < this.tempArr.length; i++) {
            this.arr1.push(Object.values(this.tempArr[i]));
          }
          //this.arr1 = [["Link", "Google", "https://www.google.com"], ["Call", "Home", "9106884674"], ["Call", "Office", "9879364472"], ["Whatsapp", "Office", "9106884674"], ["Whatsapp", "Home", "7878962975"], ["Location", "Location", "https://maps.app.goo.gl/vKBKrocP7XaeJFH19"], ["Whatsapp", "Shop", "9106884674"]],
          console.log(this.arr1);
          this.num1 = false;
          this.num2 = true;
          this.byteCardUrl = arr.data.profile.bytecard;
        }
      }
    },
  },
};
</script>

<style scoped>
.btnContainer
{
  display: flex;
  justify-content: right;
}
.logoutbtn
{
  position: absolute;
  color: red;
  font-size: 24px;
  padding: 5px;
  margin-top: 3%;
  margin-right: 4%;
}

.pos {
  position: relative;
  z-index: 1;
}
.poseye {
  position: absolute;
  z-index: 3;
  margin-left: -5%;
  margin-top: 1%;
}
.companylogo {
  width: 150px;
  height: 45px;
}
.adminhead {
  margin-top: -5%;
}
.mainlogo {
  height: 15px;
  width: 25px;
  margin-left: -45%;
  cursor: pointer;
}
span {
  cursor: pointer;
}
th {
  padding-right: 40px;
}
td {
  padding-top: -200px;
}
.myimg {
  height: 20px;
}
.fsm {
  font-size: 10px;
  opacity: 0.7;
  margin-top: 10px;
}
.disp {
  display: none;
}
a {
  text-decoration: none;
  color: black;
}

.myicon1 {
  margin-top: -60%;
  margin-right: 0%;
  margin-left: -20%;
}
.myicon {
  margin-top: -5px;
  margin-right: 5%;
}
.badgepos {
  margin-top: -35px;
}
.names {
  margin-left: 0%;
  font-size: 25px;
}
.new-chat-window {
  position: relative;
  width: 600px;
  display: block;
  margin: 10px auto;
  text-align: center;
  z-index: 2;
  margin-top: 5%;
  background-color: white;
}

.new-chat-window .fa {
  position: absolute;
  top: 13px;
  left: 10px;
}

input:focus {
  outline-color: rgb(170, 208, 228);
}

.new-chat-window-input {
  border: 1px solid #ccc;
  line-height: 40px;
  padding-left: 50px;
  z-index: 1;
  width: 600px;
  border-radius: 7px;
  height: 40px;
  background-color: white;
  border: 1px solid #c2ebff;
}
.headingdiv {
  text-align: center;
  margin-top: 5%;
}
.btn {
  height: 50px;
  background-color: rgb(185, 203, 203);
  width: 230px;
  border-radius: 7px;
  font-size: 20px;
  border: 2px solid rgb(182, 217, 217);
  margin-top: 5%;
  background-color: #0778b1;
  color: white;
}
.btn:hover {
  background-color: rgb(182, 217, 217);
}
@media only screen and (max-width: 700px) {
  .new-chat-window {
    width: 400px;
    margin-top: 10%;
  }
  .new-chat-window-input {
    width: 400px;
  }
  .headingdiv {
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .poseye {
    margin-left: -7%;
    margin-top: 1.5%;
  }
}
.loginlogo {
  float: left;
  height: 15px;
  font-size: 25px;
  position: absolute;
  margin-left: 2%;
  margin-top: -0.5%;
  color: #6da0b8;
  z-index: 2;
}
.loginlogo1 {
  float: left;
  height: 15px;
  font-size: 20px;
  position: absolute;
  margin-left: 2.5%;
  margin-top: 0.5%;
  color: #6da0b8;
}
@media only screen and (max-width: 500px) {
  .new-chat-window {
    width: 270px;
    margin-top: 15%;
  }
  .new-chat-window-input {
    width: 270px;
  }
  .headingdiv {
    margin-top: 20%;
    margin-bottom: 10%;
  }
  .btn {
    margin-top: 15%;
    width: 180px;
  }
  .loginlogo {
    margin-left: 4%;
    margin-top: -1%;
  }
  .loginlogo1 {
    margin-left: 5%;
  }
  .poseye {
    margin-left: -10%;
    margin-top: 2%;
  }
}
.d1 {
  padding: 5%;
  text-align: center;
}
.tf {
  height: 40px;
  width: 600px;
}
.selection {
  background-color: white;
  text-align: center;
  color: rgb(96, 88, 88);
  width: 100%;
  height: 15%;
  border: 1px solid rgb(59, 57, 57);
  cursor: pointer;
  font-size: 15px;
}
.tfw {
  width: 500px;
}
.up {
  width: 100%;
  height: 80px;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
}
.down {
  bottom: 0px;
  position: absolute;
  width: 100%;
  height: 80px;
  overflow: hidden;
  position: fixed;
}
.addbtn {
  margin-left: 80%;
  border: 1px solid;
  height: 60px;
  width: 50px;
  font-size: 50px;
  border-radius: 50%;
  background-color: rgb(93, 67, 198);
  font-size: 30px;
  color: white;
  overflow-x: hidden;
}
.addbtn:hover {
  background-color: rgb(222, 111, 83);
  color: white;
}
.main {
  margin-top: -4%;
  width: 50%;
  border-radius: 25px;
  padding-top: 2%;
  padding-bottom: 2%;
}
.main .product {
  display: flex;
  justify-content: left;
}
.main .badge {
  display: flex;
  justify-content: right;
}
.outer {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 630px) {
  .main {
    width: 80%;
  }
  .tfw {
    width: 300px;
  }
}
@media only screen and (max-width: 400px) {
  .addbtn {
    margin-left: 80%;
  }
  .tfw {
    width: 200px;
  }
  .tf {
    height: 30px;
    width: 300px;
  }
}
@media only screen and (max-width: 730px) {
  .tf {
    width: 400px;
  }
}
@media only screen and (max-width: 450px) {
  .tf {
    width: 280px;
  }
  .mainlogo {
    margin-top: 5%;
    margin-left: -80%;
  }
}
@media only screen and (min-width: 700px) {
  .mainlogo {
    margin-top: -5%;
  }
  .myicon1 {
    margin-top: -60%;
  }
}
.point {
  cursor: pointer;
  border-bottom: 1px solid rgb(90, 86, 86);
}
</style>