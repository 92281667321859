<template>
  <div class="mainDiv">
    <link
      href="https://fonts.googleapis.com/css?family=Noto Sans"
      rel="stylesheet"
    />
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"
    />
    <div>
      <div v-if="arr1.length === 0">
        <div class="toggleView">
          <p class="viewTitle">Select Page View Type</p>
          <p class="viewInfo">Your Personal Page will be shown as per selected option</p>
          <input
            type="radio"
            name="myView"
            class="myView myView1"
            value="grid"
            v-model="myView"
            @change="checkView()"
          /><span class="mytxt">Grid-View</span>
          <input
            type="radio"
            name="myView"
            class="myView"
            value="list"
            v-model="myView"
            @change="checkView()"
          /><span class="mytxt">List-View</span>
        </div>
        <noItem />
      </div>
      <div v-else class="mainDiv">
        <div class="toggleView">
          <p class="viewTitle">Select Page View Type</p>
          <p class="viewInfo">Your Personal Page will be shown as per selected option</p>
          <input
            type="radio"
            name="myView"
            class="myView myView1"
            value="grid"
            v-model="myView"
            @change="checkView()"
          /><span class="mytxt">Grid-View</span>
          <input
            type="radio"
            name="myView"
            class="myView"
            value="list"
            v-model="myView"
            @change="checkView()"
          /><span class="mytxt">List-View</span>
        </div>
        <draggable
          v-model="arr1"
          @change="changeDataOrder"
          :disabled="disabled"
        >
          <div v-for="(i, index) in arr1" :key="i.id" class="outer">
            <div
              class="main"
              v-if="arr1.length !== 0 && arr1[index][0] == 'Whatsapp'"
            >
              <b-alert
                show
                :variant="alertCol"
                @click="openForm(index)"
                class="point"
              >
                <span class="names">
                  <table>
                    <tr>
                      <th rowspan="2">
                        <label>
                          <i
                            class="bi bi-whatsapp"
                            style="color: #0e8403; font-size: 25px"
                          ></i>
                        </label>
                      </th>
                      <td>
                        <span class="s1">{{ arr1[index][1] }}</span>
                      </td>
                      <!-- <td class="dragWidth">
                        <i class="bi bi-grip-vertical dragPos" style="color: #A3A3A3; font-size: 25px;"></i>
                      </td> -->
                    </tr>
                    <tr>
                      <td>
                        <span class="s2">{{ arr1[index][2] }}</span>
                      </td>
                    </tr>
                  </table>
                </span>
              </b-alert>
              <span class="dragWidth" @touchstart="enableDragging" @touchend="disableDragging" @mouseover="enableDragging" @mouseout="disableDragging">
                <i class="bi bi-grip-vertical dragPos" style="color: #A3A3A3; font-size: 30px;"></i>
              </span>
            </div>
            <div
              class="main"
              v-else-if="arr1.length !== 0 && arr1[index][0] == 'Location'"
            >
              <b-alert
                show
                :variant="alertCol"
                @click="openForm(index)"
                class="point"
              >
                <span class="names">
                  <table>
                    <tr>
                      <th rowspan="2">
                        <label>
                          <i
                            class="bi bi-geo-alt"
                            style="color: #002a68; font-size: 25px"
                          ></i>
                        </label>
                      </th>
                      <td>
                        <span class="s1">{{ arr1[index][1] }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="s2">{{ arr1[index][2] }}</span>
                      </td>
                    </tr>
                  </table>
                </span>
              </b-alert>
              <span class="dragWidth" @touchstart="enableDragging" @touchend="disableDragging" @mouseover="enableDragging" @mouseout="disableDragging">
                <i class="bi bi-grip-vertical dragPos" style="color: #A3A3A3; font-size: 30px;"></i>
              </span>
            </div>
            <div
              class="main"
              v-else-if="arr1.length !== 0 && arr1[index][0] == 'Link'"
            >
              <b-alert
                show
                :variant="alertCol"
                @click="openForm(index)"
                class="point"
              >
                <span class="names">
                  <table>
                    <tr>
                      <th rowspan="2">
                        <label>
                          <i
                            class="bi bi-link-45deg"
                            style="color: #df7800; font-size: 28px"
                          ></i>
                        </label>
                      </th>
                      <td>
                        <span class="s1">{{ arr1[index][1] }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="s2">{{ arr1[index][2] }}</span>
                      </td>
                    </tr>
                  </table>
                </span>
              </b-alert>
              <span class="dragWidth" @touchstart="enableDragging" @touchend="disableDragging" @mouseover="enableDragging" @mouseout="disableDragging">
                <i class="bi bi-grip-vertical dragPos" style="color: #A3A3A3; font-size: 30px;"></i>
              </span>
            </div>
            <div
              class="main"
              v-else-if="arr1.length !== 0 && arr1[index][0] == 'Call'"
            >
              <b-alert
                show
                :variant="alertCol"
                @click="openForm(index)"
                class="point"
              >
                <span class="names">
                  <table>
                    <tr>
                      <th rowspan="2">
                        <label>
                          <i
                            class="bi bi-telephone"
                            style="color: #009083; font-size: 25px"
                          ></i>
                        </label>
                      </th>
                      <td>
                        <span class="s1">{{ arr1[index][1] }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="s2">{{ arr1[index][2] }}</span>
                      </td>
                    </tr>
                  </table>
                </span>
              </b-alert>
              <span class="dragWidth" @touchstart="enableDragging" @touchend="disableDragging" @mouseover="enableDragging" @mouseout="disableDragging">
                <i class="bi bi-grip-vertical dragPos" style="color: #A3A3A3; font-size: 30px;"></i>
              </span>
            </div>
            <div
              class="main"
              v-else-if="arr1.length !== 0 && arr1[index][0] == 'Email'"
            >
              <b-alert
                show
                :variant="alertCol"
                @click="openForm(index)"
                class="point"
              >
                <span class="names">
                  <table>
                    <tr>
                      <th rowspan="2">
                        <label>
                          <i
                            class="bi bi-envelope"
                            style="color: #025f8d; font-size: 25px"
                          ></i>
                        </label>
                      </th>
                      <td>
                        <span class="s1">{{ arr1[index][1] }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="s2">{{ arr1[index][2] }}</span>
                      </td>
                    </tr>
                  </table>
                </span>
              </b-alert>
              <span class="dragWidth" @touchstart="enableDragging" @touchend="disableDragging" @mouseover="enableDragging" @mouseout="disableDragging">
                <i class="bi bi-grip-vertical dragPos" style="color: #A3A3A3; font-size: 30px;"></i>
              </span>
            </div>
            <div
              class="main"
              v-else-if="arr1.length !== 0 && arr1[index][0] == 'Website'"
            >
              <b-alert
                show
                :variant="alertCol"
                @click="openForm(index)"
                class="point"
              >
                <span class="names">
                  <table>
                    <tr>
                      <th rowspan="2">
                        <label>
                          <i
                            class="bi bi-globe"
                            style="color: #900045; font-size: 25px"
                          ></i>
                        </label>
                      </th>
                      <td>
                        <span class="s1">{{ arr1[index][1] }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="s2">{{ arr1[index][2] }}</span>
                      </td>
                    </tr>
                  </table>
                </span>
              </b-alert>
              <span class="dragWidth" @touchstart="enableDragging" @touchend="disableDragging" @mouseover="enableDragging" @mouseout="disableDragging">
                <i class="bi bi-grip-vertical dragPos" style="color: #A3A3A3; font-size: 30px;"></i>
              </span>
            </div>
            <div
              class="main"
              v-else-if="arr1.length !== 0 && arr1[index][0] == 'Reviews'"
            >
              <b-alert
                show
                :variant="alertCol"
                @click="openForm(index)"
                class="point"
              >
                <span class="names">
                  <table>
                    <tr>
                      <th rowspan="2">
                        <label>
                          <i
                            class="bi bi-star"
                            style="color: #035684; font-size: 25px"
                          ></i>
                        </label>
                      </th>
                      <td>
                        <span class="s1">{{ arr1[index][1] }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="s2">{{ arr1[index][2] }}</span>
                      </td>
                    </tr>
                  </table>
                </span>
              </b-alert>
              <span class="dragWidth" @touchstart="enableDragging" @touchend="disableDragging" @mouseover="enableDragging" @mouseout="disableDragging">
                <i class="bi bi-grip-vertical dragPos" style="color: #A3A3A3; font-size: 30px;"></i>
              </span>
            </div>
            <div
              class="main"
              v-else-if="arr1.length !== 0 && arr1[index][0] == 'Download'"
            >
              <b-alert
                show
                :variant="alertCol"
                @click="openForm(index)"
                class="point"
              >
                <span class="names">
                  <table>
                    <tr>
                      <th rowspan="2">
                        <label>
                          <i
                            class="bi bi-download"
                            style="color: #888b00; font-size: 25px"
                          ></i>
                        </label>
                      </th>
                      <td>
                        <span class="s1">{{ arr1[index][1] }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="s2">My File Details</span>
                      </td>
                    </tr>
                  </table>
                </span>
              </b-alert>
              <span class="dragWidth" @touchstart="enableDragging" @touchend="disableDragging" @mouseover="enableDragging" @mouseout="disableDragging">
                <i class="bi bi-grip-vertical dragPos" style="color: #A3A3A3; font-size: 30px;"></i>
              </span>
            </div>
            <div
              class="main"
              v-else-if="arr1.length !== 0 && (arr1[index][0] == 'Vcard' || arr1[index][0] == 'Save Contact')"
            >
              <b-alert
                show
                :variant="alertCol"
                @click="openForm(index)"
                class="point"
              >
                <span class="names">
                  <table>
                    <tr>
                      <th rowspan="2">
                        <label>
                          <i
                            class="bi bi-credit-card-2-front"
                            style="color: #7e0181; font-size: 25px"
                          ></i>
                        </label>
                      </th>
                      <td>
                        <span class="s1">{{ arr1[index][1] }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="s2">My Card Details</span>
                      </td>
                    </tr>
                  </table>
                </span>
              </b-alert>
              <span class="dragWidth" @touchstart="enableDragging" @touchend="disableDragging" @mouseover="enableDragging" @mouseout="disableDragging">
                <i class="bi bi-grip-vertical dragPos" style="color: #A3A3A3; font-size: 30px;"></i>
              </span>
            </div>
            <div class="main" v-else-if="arr1.length !== 0">
              <b-alert
                show
                :variant="alertCol"
                @click="openForm(index)"
                class="point"
              >
                <span class="names">
                  <table>
                    <tr>
                      <td>
                        <span class="dis">{{ arr1[index][1] }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="dis fsm">{{ arr1[index][2] }}</span>
                      </td>
                    </tr>
                  </table>
                </span>
              </b-alert>
            </div>
          </div>
        </draggable>
      </div>
      <div>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent>
            <template v-slot:activator="{ props }">
              <div class="down">
                <v-btn class="addbtn" v-bind="props"> + </v-btn>
              </div>
            </template>
            <v-card class="mycard">
              <v-card-title>
                <span class="text-h5">Add-Record</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <div class="content cont">
                      <br />
                      <select v-model="selectedVal" class="selection" required>
                        <option value="" disabled selected>Select</option>
                        <option v-for="i in arr2" :key="i.id">{{ i }}</option>
                      </select>
                      <div v-if="selectedVal == 'Save Contact'">
                        <v-col cols="12">
                          <v-text-field
                            class="tfw"
                            label="Enter Label"
                            v-model="key"
                            required
                          ></v-text-field>
                        </v-col>
                      </div>
                      <div v-else-if="selectedVal == 'Download'">
                        <v-col cols="12">
                          <v-text-field
                            class="tfw"
                            label="Enter Label"
                            v-model="key"
                            required
                          ></v-text-field>
                          <v-col cols="12">
                            Upload File :-
                            <input type="file" @change="processFile($event)" />
                          </v-col>
                        </v-col>
                      </div>
                      <div v-else>
                        <v-col cols="12">
                          <v-text-field
                            class="tfw"
                            label="Enter Label"
                            v-model="key"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            class="tfw"
                            label="Enter Value"
                            v-model="val"
                            required
                          ></v-text-field>
                        </v-col>
                      </div>
                    </div>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue-darken-1"
                  text
                  @click="
                    setDownloadFile();
                    add();
                  "
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div v-if="formDialog">
        <addrec
          :email="email"
          :password="password"
          :num="myNum"
          :arr="arr1"
          @closeDialog="formDialog = false"
        />
      </div>
    </div>
    <div>
      <div class="d1" v-if="noval">
        <div class="headingdiv">
          <img :src="require('@/assets/loginlogo.png')" alt="mylogo" />
        </div>
        <v-container>
          <v-col cols="12">
            <div class="new-chat-window">
              <img
                class="loginlogo"
                :src="require('@/assets/mail.png')"
                alt="mail"
              />
              <input
                type="text"
                class="new-chat-window-input"
                v-model="email"
                required
              />
            </div>
            <div class="new-chat-window">
              <img
                class="loginlogo"
                :src="require('@/assets/key.png')"
                alt="mail"
              />
              <input
                v-if="showPassword"
                type="text"
                class="new-chat-window-input"
                v-model="password"
                required
              />
              <input
                v-else
                type="password"
                class="new-chat-window-input"
                v-model="password"
                required
              />
              <span class="icon is-small is-right" @click="toggleShow">
                <i
                  class="bi"
                  :class="{
                    'bi-eye-slash': showPassword,
                    'bi-eye': !showPassword,
                  }"
                ></i>
              </span>
            </div>
          </v-col>
          <button @click="getData()" class="btn">Login</button>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import addrec from "./addRec.vue";
import noItem from "./noItems.vue";
import { VueDraggableNext } from "vue-draggable-next";
export default {
  emits: ["response"],
  created() {},
  name: "adminOperation",
  data() {
    return {
      showPassword: false,
      url: "",
      pass: null,
      arr1: [],
      arr2: [
        "Whatsapp",
        "Call",
        "Link",
        "Location",
        "Email",
        "Download",
        "Save Contact",
        "Reviews",
        "Website",
      ],
      keyArr: ["type", "label", "value"],
      dialog: false,
      type: "",
      key: "",
      val: "",
      tempArr: [],
      temp: [],
      selectedVal: "",
      myNum: 0,
      formDialog: false,
      num2: true,
      email: "",
      password: "",
      myFile: "",
      alertCol: "light",
      showpersonal: false,
      noval: false,
      selectedNum: 0,
      selectedArr: [],
      tempSelectedArr: [],
      theUrl: "",
      checkAl: false,
      myLogo: "",
      bytecard: "",
      oname: "",
      cname: "",
      myView: "",
      disabled: true,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  components: {
    addrec,
    noItem,
    draggable: VueDraggableNext,
  },
  methods: {
    something(){
      console.log("Hey");
    },
    enableDragging(){
      this.disabled = false;
      console.log("start");
    },
    disableDragging(){
      this.disabled = true;
      console.log("end");
    },  
    changeDataOrder() {
      console.log(this.arr1);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", "935c37eb-8c56-4041-8d56-7bcbe896aa0c");

      var options = '{"items":[';
      for (var i = 0; i < this.arr1.length; i++) {
        options = options.concat('{"');
        for (var j = 0; j < 3; j++) {
          if (j === 2) {
            options = options.concat(
              this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
            );
          } else {
            options =
              options.concat(
                this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
              ) + ',"';
          }
        }
        if (i !== this.arr1.length - 1) {
          options = options.concat("},");
        } else {
          options = options.concat("}]}");
        }
      }
      var myUrl = '"' + this.$route.params.id + '"';

      console.log("Options ->" + options);
      var raw =
        '{\r\n"hwId": ' +
        myUrl +
        ',\r\n"action":{ "data":[{"type":"' +
        this.selectedArr[0][0] +
        '", "label":"' +
        this.selectedArr[0][1] +
        '", "value":"' +
        this.selectedArr[0][2] +
        '"}], "num":"' +
        this.selectedNum +
        '", "url":"' +
        this.theUrl +
        '", "view":"' +
        this.myView +
        '"},\r\n"options":' +
        options +
        "}";
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch("https://api.bytecard.in/api/nfc/update", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      console.warn(raw);
      this.disabled = true;
    },
    checkView() {
      console.log(this.myView);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", "935c37eb-8c56-4041-8d56-7bcbe896aa0c");

      var options = '{"items":[';
      for (var i = 0; i < this.arr1.length; i++) {
        options = options.concat('{"');
        for (var j = 0; j < 3; j++) {
          if (j === 2) {
            options = options.concat(
              this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
            );
          } else {
            options =
              options.concat(
                this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
              ) + ',"';
          }
        }
        if (i !== this.arr1.length - 1) {
          options = options.concat("},");
        } else {
          options = options.concat("}]}");
        }
      }
      var myUrl = '"' + this.$route.params.id + '"';

      console.log("Options ->" + options);
      var raw =
        '{\r\n"hwId": ' +
        myUrl +
        ',\r\n"action":{ "data":[{"type":"' +
        this.selectedArr[0][0] +
        '", "label":"' +
        this.selectedArr[0][1] +
        '", "value":"' +
        this.selectedArr[0][2] +
        '"}], "num":"' +
        this.selectedNum +
        '", "url":"' +
        this.theUrl +
        '", "view":"' +
        this.myView +
        '"},\r\n"options":' +
        options +
        "}";
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch("https://api.bytecard.in/api/nfc/update", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      console.warn(raw);
    },
    processFile(event) {
      this.myFile = event.target.files[0];
      console.log(this.myFile.name);
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    setDownloadFile() {
      if (this.selectedVal === "Save Contact") {
        this.temp = [
          this.selectedVal,
          this.key,
          "https://api.bytecard.in/api/nfc/vcard/download?hwId=" +
            this.$route.params.id,
        ];
        this.arr1.push(this.temp);
        this.saveJsonData();
        this.selectedVal = "";
        this.key = "";
        this.val = "";
        this.checkAl = true;
      } else if (this.selectedVal === "Download") {
        var myHeaders = new Headers();
        myHeaders.append("token", "935c37eb-8c56-4041-8d56-7bcbe896aa0c");

        var formdata = new FormData();
        formdata.append("hwId", this.$route.params.id);
        formdata.append("attachment", this.myFile, this.myFile.name);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        fetch("https://api.bytecard.in/api/nfc/upload", requestOptions)
          .then((response) => response.text())
          .then((result) => this.writeFile(result))
          .catch((error) => console.log("error", error));
      }
    },
    writeFile(result) {
      this.temp = [this.selectedVal, this.key, JSON.parse(result).data];
      this.arr1.push(this.temp);
      this.saveJsonData();
      this.selectedVal = "";
      this.key = "";
      this.val = "";
      this.checkAl = true;
    },
    add() {
      if (
        this.selectedVal !== "Download" &&
        this.selectedVal !== "Save Contact" &&
        this.selectedVal !== "Vcard" &&
        this.checkAl === false
      ) {
        if (this.selectedVal === "" || this.key === "") {
          alert("Any of the fields cannot be empty...");
          this.selectedVal = "";
          this.key = "";
          this.val = "";
        } else if (
          (this.selectedVal === "Whatsapp" || this.selectedVal === "Call") &&
          (this.val.length > 12 || this.val.length < 10)
        ) {
          alert("Invalid Number...");
        } else {
          console.log(this.val);
          this.dialog = false;
          this.temp = [this.selectedVal, this.key, this.val];
          this.arr1.push(this.temp);
          this.saveJsonData();
          this.selectedVal = "";
          this.key = "";
          this.val = "";
        }
      }
      this.checkAl = false;
    },
    saveJsonData() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", "935c37eb-8c56-4041-8d56-7bcbe896aa0c");

      var options = '{"items":[';
      for (var i = 0; i < this.arr1.length; i++) {
        options = options.concat('{"');
        for (var j = 0; j < 3; j++) {
          if (j === 2) {
            options = options.concat(
              this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
            );
          } else {
            options =
              options.concat(
                this.keyArr[j] + '":' + JSON.stringify(this.arr1[i][j])
              ) + ',"';
          }
        }
        if (i !== this.arr1.length - 1) {
          options = options.concat("},");
        } else {
          options = options.concat("}]}");
        }
      }
      var myUrl = '"' + this.$route.params.id + '"';

      console.log("Options ->" + options);
      var raw =
        '{\r\n"hwId": ' +
        myUrl +
        ',\r\n"action":{ "data":[{"type":"' +
        this.selectedArr[0][0] +
        '", "label":"' +
        this.selectedArr[0][1] +
        '", "value":"' +
        this.selectedArr[0][2] +
        '"}], "num":"' +
        this.selectedNum +
        '", "url":"' +
        this.theUrl +
        '", "view":"' +
        this.myView +
        '"},\r\n"options":' +
        options +
        "}";
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch("https://api.bytecard.in/api/nfc/update", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      console.warn(raw);
      alert("Saved Successfully...");
      this.dialog = false;
      this.getData();
    },
    openForm(index) {
      this.formDialog = true;
      this.myNum = index;
    },
    getData() {
      var formdata = new FormData();
      console.log(this.arr1);
      formdata.append("hwId", this.$route.params.id);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch("https://api.bytecard.in/api/nfc/get", requestOptions)
        .then((response) => response.text())
        .then((result) => this.printData(result))
        .catch((error) => alert(error));
    },
    printData(result) {
      var arr = JSON.parse(result);
      if (arr.status == false) {
        alert(arr.message);
        console.log(arr);
      } else {
        console.log(arr.data.options);
        this.tempArr = Object.values(arr.data.options.items);
        this.arr1 = [];
        for (var i = 0; i < this.tempArr.length; i++) {
          this.arr1.push(Object.values(this.tempArr[i]));
        }
        //this.arr1 = [["Link", "Google", "https://www.google.com"], ["Call", "Home", "9106884674"], ["Call", "Office", "9879364472"], ["Whatsapp", "Office", "9106884674"], ["Whatsapp", "Home", "7878962975"], ["Location", "Location", "https://maps.app.goo.gl/vKBKrocP7XaeJFH19"], ["Whatsapp", "Shop", "9106884674"]],
        console.log(this.arr1);
        this.num1 = false;
        this.num2 = true;
        this.tempSelectedArr = Object.values(arr.data.action.data);
        for (var j = 0; j < this.tempSelectedArr.length; j++) {
          this.selectedArr.push(Object.values(this.tempSelectedArr[j]));
        }
        this.theUrl = arr.data.action.url;
        this.selectedNum = parseInt(arr.data.action.num);
        this.myLogo = arr.data.profile.logo;
        this.bytecard = arr.data.profile.bytecard;
        this.oname = arr.data.profile.owner_name;
        this.cname = arr.data.profile.company_name;
        this.myView = arr.data.action.view;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.viewTitle{
  color: #383838;
  font-size: 18px;
  margin-top: -2%;
  text-align: left;
  font-weight: 500;
}
.viewInfo{
  text-align: left;
  color: #878787;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  margin-top: -4%;
}
.dragPos {
  position: absolute;
  cursor: all-scroll;
}
.toggleView {
  width: 50%;
  height: 125px;
  margin-bottom: 4%;
  display: inline-block;
  border-radius: 10px;
  padding: 20px;
  background-color: rgba(7, 120, 177, 0.12);
}
.mytxt {
  font-size: 14px;
  margin-left: 3%;
}
.myView {
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-right: 1%;
  margin-left: 15%;
}
.myView1{
  margin-left: -24%;
}
.s1 {
  color: #383838;
  font-size: 17px;
  font-weight: 500;
}
.s2 {
  font-size: 12px;
  color: #878787;
  white-space: nowrap;
}
label {
  display: inline-block;
  width: 50px;
  text-align: left;
}
td,
th {
  text-align: left;
}
.adminhead {
  margin-top: -5%;
}
.mainlogo {
  height: 15px;
  width: 25px;
  margin-left: -45%;
  cursor: pointer;
}
span {
  cursor: pointer;
}
.myimg {
  height: 20px;
  width: 23px;
}
.lmyimg {
  height: 27px;
  width: 27px;
}
.dis {
  color: #383838;
  margin-left: 15%;
}
.fsm {
  margin-left: 15%;
  color: #878787;
  white-space: nowrap;
}
.ldis {
  color: #383838;
  margin-left: -60%;
}
.lfsm {
  margin-left: 5%;
  color: #878787;
  white-space: nowrap;
}
.mdis {
  color: #383838;
  margin-left: 5%;
}
.mfsm {
  margin-left: 5%;
  color: #878787;
  white-space: nowrap;
}
.disp {
  display: none;
}
a {
  text-decoration: none;
  color: black;
}

.myicon1 {
  margin-top: -60%;
}
.myicon {
  margin-top: -5px;
}
.badgepos {
  margin-top: -35px;
}

.new-chat-window {
  position: relative;
  width: 600px;
  display: block;
  margin: 10px auto;
  text-align: center;
  z-index: 2;
  margin-top: 5%;
  background-color: white;
}

.new-chat-window .fa {
  position: absolute;
  top: 13px;
  left: 10px;
}

input:focus {
  outline-color: rgb(170, 208, 228);
}

.new-chat-window-input {
  border: 1px solid #ccc;
  line-height: 40px;
  z-index: 1;
  width: 600px;
  border-radius: 7px;
  height: 40px;
  background-color: white;
  border: 1px solid #c2ebff;
}
.headingdiv {
  margin-top: 5%;
}
.btn {
  height: 50px;
  background-color: rgb(185, 203, 203);
  width: 230px;
  border-radius: 7px;
  font-size: 20px;
  border: 2px solid rgb(182, 217, 217);
  margin-top: 5%;
  background-color: #0778b1;
  color: white;
}
.btn:hover {
  background-color: rgb(182, 217, 217);
}
@media only screen and (max-width: 700px) {
  .new-chat-window {
    width: 400px;
    margin-top: 10%;
  }
  .new-chat-window-input {
    width: 400px;
  }
  .headingdiv {
    margin-top: 10%;
    margin-bottom: 5%;
  }
}
@media only screen and (max-width: 500px) {
  .new-chat-window {
    width: 270px;
    margin-top: 15%;
  }
  .new-chat-window-input {
    width: 270px;
  }
  .headingdiv {
    margin-top: 20%;
    margin-bottom: 10%;
  }
  .btn {
    margin-top: 15%;
    width: 180px;
  }
}
.loginlogo {
  float: left;
  height: 15px;
}
.d1 {
  padding: 5%;
  text-align: center;
}
.tf {
  height: 40px;
  width: 600px;
}
.selection {
  background-color: white;
  text-align: center;
  color: rgb(96, 88, 88);
  width: 100%;
  height: 15%;
  border: 1px solid rgb(59, 57, 57);
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 10%;
}
.tfw {
  width: 500px;
}
.up {
  width: 100%;
  height: 80px;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
}
.down {
  bottom: 0px;
  position: absolute;
  width: 100%;
  height: 80px;
  overflow: hidden;
  position: fixed;
  z-index: 3;
}
.addbtn {
  margin-left: 80%;
  border: 1px solid;
  height: 60px;
  width: 50px;
  border-radius: 50%;
  background-color: #0778B1;
  font-size: 37px;
  color: white;
  overflow-x: hidden;
}
.addbtn:hover {
  background-color: rgb(222, 111, 83);
  color: white;
}
.main {
  margin-top: -4%;
  width: 50%;
  border-radius: 25px;
  padding-top: 2%;
  padding-bottom: 2%;
  overflow: hidden;
  font-family: "noto-sans";
}

.main .badge {
  display: flex;
  justify-content: right;
}
.outer {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 630px) {
  .main {
    width: 90%;
  }
  .tfw {
    width: 300px;
  }
}
@media only screen and (max-width: 400px) {
  .addbtn {
    margin-left: 80%;
  }
  .tfw {
    width: 200px;
  }
  .tf {
    height: 30px;
    width: 300px;
  }
  label {
    width: 40px;
  }
}
@media only screen and (max-width: 730px) {
  .tf {
    width: 400px;
  }
}
@media only screen and (max-width: 450px) {
  .tf {
    width: 280px;
  }
  .mainlogo {
    margin-top: 5%;
    margin-left: -80%;
  }
}
@media only screen and (min-width: 700px) {
  .mainlogo {
    margin-top: -5%;
  }
  .myicon1 {
    margin-top: -60%;
  }
}
@media only screen and (max-width: 550px) {
  .toggleView {
    width: 90%;
    height: 20%;
    padding: 20px;
  }
}
.point {
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  z-index: 1;
  border-radius: 0px;
  width: 100%;
}
.dragWidth{
  position: absolute;
  z-index: 2;
  margin-left: 20%;
  margin-top: -7%;
}
.mainDiv,
.names {
  font-family: "Noto Sans";
}
@media only screen and (max-width: 1000px)
{
  .dragWidth{
    margin-left: 20%;
    margin-top: -9%;
  }
}
@media only screen and (max-width: 880px)
{
  .dragWidth{
    margin-left: 20%;
    margin-top: -10%;
  }
}
@media only screen and (max-width: 800px)
{
  .dragWidth{
    margin-left: 20%;
    margin-top: -12%;
  }
}
@media only screen and (max-width: 680px)
{
  .dragWidth{
    margin-left: 20%;
    margin-top: -14%;
  }
}
@media only screen and (max-width: 620px)
{
  .dragWidth{
    margin-left: 32%;
    margin-top: -16%;
  }
}
@media only screen and (max-width: 550px)
{
  .dragWidth{
    margin-left: 35%;
    margin-top: -18%;
  }
}
@media only screen and (max-width: 485px)
{
  .dragWidth{
    margin-left: 36%;
    margin-top: -18%;
  }
}
@media only screen and (max-width: 430px)
{
  .dragWidth{
    margin-left: 32%;
    margin-top: -22%;
  }
}
@media only screen and (max-width: 400px)
{
  .dragWidth{
    margin-left: 35%;
    margin-top: -24%;
  }
}
@media only screen and (max-width: 340px)
{
  .dragWidth{
    margin-left: 32%;
    margin-top: -27%;
  }
}
</style>